module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://insiderprogram.bhvr.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Project T","short_name":"Project T","lang":"en","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"./static/favicon.png","legacy":false,"include_favicon":false,"localize":[{"start_url":"/de/","lang":"de","name":"Project T","short_name":"Project T"},{"start_url":"/fr/","lang":"fr","name":"Project T","short_name":"Project T"},{"start_url":"/ja/","lang":"ja","name":"Project T","short_name":"Project T"},{"start_url":"/es/","lang":"es","name":"Project T","short_name":"Project T"},{"start_url":"/pt/","lang":"pt","name":"Project T","short_name":"Project T"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"111be5a1b0f5152190e0478bac321258"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
