import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';

function CustomFooterContent() {
  const { ref, inView } = useInView({
    threshold: 0.75,
    triggerOnce: true,
  });

  return (
    <div ref={ref} className='footer-background'>
      {inView && (
        <StaticImage
          src='../../assets/img/backgrounds/footer-background.jpg'
          alt=''
          placeholder='blurred'
          loading='lazy'
          className='gatsby-absolute pointer-events-none left-0 top-0 h-full w-full opacity-50'
          role='presentation'
        />
      )}
    </div>
  );
}

export default CustomFooterContent;
